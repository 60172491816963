import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import shippingService from "../js/shipping-service";
import PrinterDetails from "../../common/printer-details";
import html2pdf from "html2pdf.js";
import VueJsonToCsv from "vue-json-to-csv";
export default {
  name: "Fulfillment Hold Release",
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      favouriteList: [],
      mainPage: "",
      subPage: "",
      backSlash: true,
      pageUrl: "",
      showAdd: false,
      refresh: true,
      projectData: [],
      searchRecords: "",
      totalRecords: 0,
      shipAuditItems: [],
      projectselect: "",
      selectedStatus: "",
      searchIdentifier: "",
      printDisabled: true,
      exportDisabled: true,
      csvDisabled: true,
      pdfName: "",
      excelName: "",
      csvName: "",
      showPrimary: false,
      headersShippingAudit: [
        { text: "Audit Number", value: "audit_number", class: "primary customwhite--text" },
        { text: "SO Number", value: "sono", class: "primary customwhite--text" },
        { text: "Project/Business", value: "projBusiness", class: "primary customwhite--text" },
        { text: "RMA", value: "rma", class: "primary customwhite--text" },
        { text: "Ship Date", value: "shipdate", class: "primary customwhite--text" },
        { text: "Progress", value: "box_count", class: "primary customwhite--text" },
        { text: "Status", value: "status", class: "primary customwhite--text" },
        {
          text: "Actions",
          value: "actions",
          class: "primary customwhite--text",
          sortable: false,
        },
      ],
      json_fields: {
        "Audit Number": "audit_number",
        "Serial Number": "sono",
        "Project": "project",
        "Business": "business",
        RMA: "rma",
        "Ship Date": "shipdate",
        Progress: "box_count",
        Status: "status",
      },
      labels_json: {
        audit_number: { title: "Audit Number" },
        sono: { title: "Serial Number" },
        project: { title: "Project" },
        business: { title: "Business" },
        rma: { title: "RMA" },
        shipdate: { title: "Ship Date" },
        box_count: { title: "Progress" },
        status: { title: "Status" },
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      searchList: [
        {
          text: "All Status",
          value: "",
        },
        {
          text: "Ready For Auditing",
          value: "O",
        },
        {
          text: "Assigned",
          value: "A",
        },
        {
          text: "Completed",
          value: "C",
        },
      ],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.getProjectData();
    this.getShipQueueData();
  },
  mounted() {},
  methods: {
    //Reset Function
    resetFunction() {
      this.projectselect = "";
      this.selectedStatus = "";
      this.searchIdentifier = "";
      this.totalRecords = 0;
      this.shipAuditItems = [];
      this.printDisabled = true;
      this.exportDisabled = true;
      this.csvDisabled = true;
      this.getProjectData();
      this.getShipQueueData();
    },
    //Export functionality
    fileName() {
      this.excelName =
        "Shipping_Audit_Queue_List_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".xls";
    },
    //CSV Export functionality
    csvFileName() {
      this.csvName = "Shipping_Audit_Queue_List_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_");
    },
    //Get Action Dropdown
    async getProjectData() {
      let projectData = await shippingService.getProjectList("get", parseInt(this.userId));
      if (projectData != null && projectData != undefined && projectData != {}) {
        this.projectData = projectData;
      }
    },
    //Search Event
    async getShipQueueData() {
      this.shipAuditItems = [];
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let obj = {
        proj_id: this.projectselect == "" ? 0 : this.projectselect,
        user_id: parseInt(this.userId),
        SearchStr: this.searchIdentifier == null ? "" : this.searchIdentifier,
        status: this.selectedStatus,
      };
      this.axios
        .post("sh/shipping_audit_queue", obj)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responseData = JSON.parse(response.data.body.message);
            if (responseData !== null && responseData !== undefined) {
              const newArr = responseData.map((obj) => {
                return {
                  ...obj,
                  shipdate: Utility.convertESTToLocal(obj.shipdate),
                };
              });
              this.shipAuditItems = newArr;
              this.totalRecords = responseData.length;
              this.searchClicked = true;
              this.printDisabled = false;
              this.exportDisabled = false;
              this.csvDisabled = false;
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            } else {
              let Alert = {
                type: "error",
                isShow: true,
                message: "No Shipping Orders are present to Audit",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          this.shipAuditItems = [];
          this.totalRecords = 0;
          this.searchClicked = false;
          this.printDisabled = true;
          this.exportDisabled = true;
          this.csvDisabled = true;
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //PO that is selected on s
    selectedShippingData(item) {
      let audit_id = item.ship_audit_id;
      this.$router.push({
        name: "shippingAuditQueueDetails",
        params: {
          audit_id: btoa(audit_id.toString()),
        },
      });
    },
    //Print button click event
    printRecords() {
      this.pdfName =
        "Shipping_Audit_Details_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".pdf";
      html2pdf(document.getElementById("shippingAuditDetailsTbl"), {
        margin: 0.5,
        filename: this.pdfName,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 190, letterRendering: true },
        jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
      });
    },
    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //To brings page based actions to the page
    actionSetter(action) {
      if (this.actionList !== undefined && this.actionList !== null && this.actionList !== "") {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
  },
  components: {
    breadcrumbComp,
    html2pdf,
    PrinterDetails,
    VueJsonToCsv,
  },
};
